export const trashType = {
    0: {
        type: 0,
        name: '厨余垃圾',
        eng: 'kitchenWaste',
        eng2: 'kitchenWaste',
        color: '#808080',
        typeCode: '297890255081'
    },
    1: {
        type: 1,
        name: '其他垃圾',
        eng: 'otherWaste',
        eng2: 'other',
        color: '#3091F8',
        typeCode: '297890287234'
    },
    2: {
        type: 2,
        name: '可回收物',
        eng: 'recyclableWaste',
        eng2: 'recyclable',
        color: '#6BBF3F',
        typeCode: '297890226008'
    },
    3: {
        type: 3,
        name: '有害垃圾',
        eng: 'harmfulWaste',
        eng2: 'harmful',
        color: '#E83F37',
        typeCode: '297890274683'
    }
}

export const trashName = {
    '厨余垃圾': {
        type: 0,
        color: '#808080'
    },
    '其他垃圾': {
        type: 1,
        color: '#3091F8'
    },
    '可回收物': {
        type: 2,
        color: '#6BBF3F'
    },
    '有害垃圾': {
        type: 3,
        color: '#E83F37'
    },
    '297890255081': {
        type: 0,
        color: '#808080'
    },
    '297890287234': {
        type: 1,
        color: '#3091F8'
    },
    '297890226008': {
        type: 2,
        color: '#6BBF3F'
    },
    '297890274683': {
        type: 3,
        color: '#E83F37'
    }
}

export const disposalSiteTrashName = {
    kitchenWaste: {name: '厨余垃圾', type: 2,},
    other: {name: '其他垃圾', type: 4,},
    recyclable: {name: '可回收物', type: 2,},
    harmful: {name: '有害垃圾', type: 3,},
    landscaping: {name: '园林绿化垃圾', type: 4,},
    bulkyWaste: {name: '大件垃圾', type: 5,},
    construct: {name: '建筑垃圾', type: 6,},
}
