<template>
  <div class="content">
    <a-row>
      <a-form layout="inline">
        <a-form-item label="小区名称">
          <a-input v-model="query.centerName" placeholder="请输入小区名称"/>
        </a-form-item>

        <a-form-item label="垃圾类别">
          <a-select v-model="query.trashType" placeholder="请选择垃圾类别" allowClear @change="handleChangeTrashType"
                    style="width: 200px">
            <a-select-option v-for="(trash,index) in trashType" :key="index" :value="trash.typeCode">
              <span>{{ trash.name }}</span>
            </a-select-option>
          </a-select>
        </a-form-item>

        <a-form-item label="街道">
          <a-select v-model="query.streetCode" placeholder="请选择街道" style="width: 200px">
            <a-select-option :key="-1" :value="undefined">全部</a-select-option>
            <a-select-option v-for="street in streetList" :key="street.value" :value="street.value">
              {{ street.label }}
            </a-select-option>
          </a-select>
        </a-form-item>

        <a-form-item label="扫描时间">
          <a-range-picker v-model="query.time"/>
        </a-form-item>

        <a-form-item>
          <a-button type="primary" @click="customQuery">查询</a-button>
        </a-form-item>

        <a-form-item style="color: red; font-weight: bolder;">
          默认查询前30天！
        </a-form-item>
      </a-form>
    </a-row>

    <a-row style="margin-top: 24px;">
      <a-table :columns="columns" :data-source="data" :loading="loading"
               :pagination="pagination"
               @change="handleTableChange"
               @expand="handleExpand">
        <span slot="typeCode" slot-scope="text">
          <a-tag :color="trashType[text].color">{{ trashType[text].name }}</a-tag>
        </span>

        <span slot="expandedRowRender" slot-scope="record" style="margin: 0">
          <a-row>
            <a-col :span="6">
              <a-descriptions :column="1" title="垃圾收集信息">
                <a-descriptions-item label="收集编号">
                  {{ record.collectCode }}
                </a-descriptions-item>
                <a-descriptions-item label="采集地址">
                  {{ record.houseCode }}
                </a-descriptions-item>
                <a-descriptions-item label="垃圾重量">
                  {{ record.weight }}
                </a-descriptions-item>
                <a-descriptions-item label="垃圾类别">
                  <a-tag :color="trashType[record.typeCode].color">{{ trashType[record.typeCode].name }}</a-tag>
                </a-descriptions-item>
                <a-descriptions-item label="采集环境">
                  <a-tag v-if="record.isTest" color="#f50">测试环境</a-tag>
                  <a-tag v-else>正式环境</a-tag>
                </a-descriptions-item>
              </a-descriptions>
            </a-col>

            <a-col :span="6">
              <a-descriptions :column="1" title="设备信息">
                <a-descriptions-item label="设备名称">
                  {{ record.device.deviceName }}
                </a-descriptions-item>
                <a-descriptions-item label="出厂编号">
                  {{ record.device.factoryCode }}
                </a-descriptions-item>
                <a-descriptions-item label="设备类型">
                  {{ record.device.deviceType }}
                </a-descriptions-item>
              </a-descriptions>
            </a-col>

            <a-col :span="6">
              <a-descriptions :column="1" title="清洁屋信息">
                <a-descriptions-item label="清洁屋名称">
                  {{ record.house.houseName }}
                </a-descriptions-item>
                <a-descriptions-item label="小区">
                  {{ record.centerName }}
                </a-descriptions-item>
                <a-descriptions-item label="所属行政">
                  {{ record.house.areaName }} - {{ record.house.streetName }}
                </a-descriptions-item>
                <a-descriptions-item label="经纬度">
                  {{ record.house.longitude }}, {{ record.house.latitude }}
                </a-descriptions-item>
                <a-descriptions-item label="所属序号">
                  {{ record.house.sort }}
                </a-descriptions-item>
              </a-descriptions>
            </a-col>

            <a-col v-if="false" :span="6">
              <a-descriptions :column="1" title="监控信息">
                <a-descriptions-item label="设备数量">
                  {{ record.collectCode }}
                </a-descriptions-item>
                <a-descriptions-item label="设备列表">
                </a-descriptions-item>
                <a-descriptions-item label="设备图像">
                </a-descriptions-item>
                <a-descriptions-item label="设备类型">
                </a-descriptions-item>
              </a-descriptions>
            </a-col>
          </a-row>
        </span>
      </a-table>
    </a-row>
  </div>
</template>

<script>
import {trashName, trashType} from "@/common/config";
import {queryCleanHouse, queryDevice, queryVillageRealTime} from "@/api/village";
import _ from 'lodash'
import {queryRegionNodeChild} from "@/api/region";
import moment from "moment";
import {formatToDateBegin, formatToDateEnd, formatToDateWithTime, getPreviewDate} from "@/utils/time";
import {mapState} from "vuex";

export default {
  name: "villageData",
  data() {
    return {
      trashType, trashName,
      query: {
        centerName: undefined,
        trashType: undefined,
        streetCode: undefined,
        time: [],

        sort: undefined,
        order: undefined
      },
      pagination: {
        pageSize: 10,
        current: 1,
        total: 0,
        showTotal: (total, range) => `总共 ${total} 条数据`,
      },
      columns: [
        {
          title: '小区名称',
          dataIndex: 'centerName',
          key: 'centerName',
          scopedSlots: {customRender: 'centerName'},
          align: 'center',
          // sorter: true,
        },
        {
          title: '小区编号',
          dataIndex: 'centerCode',
          key: 'centerCode',
          scopedSlots: {customRender: 'centerCode'},
          align: 'center',
          // sorter: true,
        },
        {
          title: '清洁屋编号',
          dataIndex: 'houseCode',
          key: 'houseCode',
          scopedSlots: {customRender: 'houseCode'},
          align: 'center',
          // sorter: true,
        },
        // {
        //   title: '垃圾桶编号',
        //   key: 'trashCode',
        //   dataIndex: 'trashCode',
        //   scopedSlots: {customRender: 'trashCode'},
        //   align: 'center',
        //   sorter: true,
        // },
        {
          title: '垃圾类别',
          key: 'typeCode',
          dataIndex: 'typeCode',
          scopedSlots: {customRender: 'typeCode'},
          align: 'center',
          // sorter: true,
        },
        {
          title: '垃圾重量（kg）',
          key: 'weight',
          dataIndex: 'weight',
          scopedSlots: {customRender: 'weight'},
          align: 'center',
          // sorter: true,
        },
        {
          title: '采集IP地址',
          key: 'ipAddr',
          dataIndex: 'ipAddr',
          scopedSlots: {customRender: 'ipAddr'},
          align: 'center',
          // sorter: true,
        },
        {
          title: '扫描时间',
          key: 'collectTime',
          dataIndex: 'collectTime',
          scopedSlots: {customRender: 'collectTime'},
          align: 'center',
          // sorter: true,
        },
      ],
      data: [],
      regionList: [],
      loading: true,
    }
  },
  mounted() {
    this.query.time = getPreviewDate(31, 0)

    this.queryVillageList()
  },
  methods: {
    handleChangeTrashType(e) {
      this.query.trashType = e
      this.queryVillageList()
    },
    async queryVillageList() {
      this.loading = true

      let data = await queryVillageRealTime(this.convertQuery())

      this.convertPagination(data.result)

      this.data = this.convertData(data.result.content)

      this.loading = false
    },
    handleTableChange(pagination, filters, sorter) {
      this.pagination.pageSize = pagination.pageSize
      this.pagination.current = pagination.current
      this.query.sort = sorter.field
      this.query.order = sorter.order

      this.queryVillageList()
    },
    customQuery() {
      this.pagination.current = 1
      this.queryVillageList()
    },
    convertQuery() {
      return {
        centerName: this.query.centerName,
        typeCode: this.query.trashType,
        streetCode: this.query.streetCode,
        startTime: formatToDateBegin(this.query.time[0]),
        endTime: formatToDateEnd(this.query.time[1]),

        pageSize: this.pagination.pageSize,
        pageNo: this.pagination.current,
        sort: this.query.sort,
        order: this.query.order,
      }
    },
    convertPagination(data) {
      this.pagination.current = data.pageNo + 1
      this.pagination.total = data.totalElements
    },
    convertData(data) {
      return _.map(data, (village, index) => {
        return {
          ...village,
          key: village.centerCode + village.collectTime + index,
          centerName: village.centerName,
          typeCode: trashName[village.typeName].type,
          collectTime: formatToDateWithTime(village.collectTime),
          device: {},
          house: {},
        }
      })
    },
    async handleExpand(expanded, record) {
      if (expanded) {
        record.house = (await queryCleanHouse(record.houseCode)).result
        record.device = (await queryDevice(record.deviceCode)).result
      }
    }
  },
  computed: mapState({
    streetList: state => state.user.streetList
  })
}
</script>

<style lang="scss" scoped>
.content {
  background-color: white;
  padding: 15px;
}
</style>
