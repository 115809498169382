import service from './network';

export function queryVillageRealTime(data) {
    return service.request({
        url: '/center/real/time',
        method: 'post',
        data
    })
}

export function queryVillageAnalyze(data) {
    return service.request({
        url: '/center/analysis',
        method: 'post',
        data
    })
}

export function queryVillageTrendToDay(data) {
    return service.request({
        url: '/center/trendToDay',
        method: 'post',
        data
    })
}

export function queryVillageTrendToWeek(data) {
    return service.request({
        url: '/center/trendToWeek',
        method: 'post',
        data
    })
}

export function queryVillageTrendToMonth(data) {
    return service.request({
        url: '/center/trendToMonth',
        method: 'post',
        data
    })
}

export function queryCleanHouse(data) {
    return service.request({
        url: '/center/house/info/' + data,
        method: 'get'
    })
}

export function queryDevice(data) {
    return service.request({
        url: '/center/device/info/' + data,
        method: 'get'
    })
}

export function queryNoDataVillage(data) {
    return service.request({
        url: '/center/hasNoData/',
        method: 'post',
        data
    })
}

export function queryVillageDataException(data) {
    return service.request({
        url: '/center/date/exception',
        method: 'post',
        data
    })
}

