import moment from "moment";

export function getPreviewDate(begin, end) {
    return [moment().subtract(begin, 'days'), moment().subtract(end, 'days')]
}

export function formatToDate(date, days = 0) {
    return date && moment(date).clone().add(days, 'days').format('YYYY-MM-DD')
}

export function formatToDateBegin(date) {
    return date && moment(date).format('YYYY-MM-DD')
}

export function formatToDateEnd(date) {
    return date && moment(date).clone().add(1, 'days').format('YYYY-MM-DD')
}

export function formatToDateWithTime(date) {
    return date && moment(date).format('YYYY-MM-DD HH:mm:ss')
}
